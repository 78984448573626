import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import image_logo from '../images/logo.png';

import FooterPrivacy from './Modals/FooterPrivacy';
import FooterRefund from './Modals/FooterRefund';
import FooterTerms from './Modals/FooterTerms';

const Footer = () => {
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    return (
    <footer className="fadeout">
        <div className="container">
            <div className="content">
                <div className="main-info">
                    <a href="" className="logo">
                        <img src={image_logo} alt=""/>
                        <span className="info">
                            <span className="name">0xcheats</span>
                            <span>private</span>
                        </span>
                    </a>
                    <div className="links">
                        <div>
                            <FooterPrivacy/>
                            <FooterRefund/>
                            <FooterTerms/>
                        </div>
                        <div>
                            <a href="https://vk.com/0xcheats" target="_blank">{t('footer_vk')}</a>
                            {/*<a href="https://t.me/zeroxcommunity" target="_blank">{t('footer_telegram')}</a>*/}
                            <a href="https://discord.gg/0x-community" target="_blank">{t('footer_discord')}</a>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <p>{t('footer_design')} <a>Ⓐ AdamS</a> &amp; {t('footer_layout')} <a>䖶 Hyuga</a> &amp; {t('footer_backend')} <a>Superior.</a></p>
                    <span style={{'opacity': '0.4'}}>{t('footer_layzan')}</span>
                </div>
            </div>
        </div>
    </footer>
    );
};
export default Footer;