import React, { useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useCookies } from 'react-cookie';

import axios from 'axios';
import qs from 'qs';

import { ShowSuccess, ShowError } from '../Modules/Popups';

import loading_image from '../../images/loading2.gif';
import qiwi_image from "../../images/payments/qiwi.png";
import visa_image from "../../images/payments/visa.png";
import visa2_image from "../../images/payments/visa2.png";

const AccountCoder_Terminal = (props) => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);

    let intervalId = null;
    let lastRequestDone = true;

    function decrypt(encryptedData) {
        const encryptedBytes = atob(encryptedData);
        const keyBytes = unescape(encodeURIComponent("MyEncryptionKey123"));

        const decryptedBytes = new Uint8Array(encryptedBytes.length);

        for (let i = 0; i < encryptedBytes.length; i++) {
            const encryptedByte = encryptedBytes.charCodeAt(i);
            const keyByte = keyBytes.charCodeAt(i % keyBytes.length);

            // Пропускаем символы, не соответствующие ASCII
            if (encryptedByte > 255 || keyByte > 255) {
                decryptedBytes[i] = encryptedByte;
            } else {
                decryptedBytes[i] = encryptedByte ^ keyByte;
            }
        }

        const decoder = new TextDecoder('utf-8');
        const decryptedString = decoder.decode(decryptedBytes);

        return JSON.parse(decryptedString);
    }

    function GetHwid() {
        var browser = navigator.userAgent;
        var gl = document.createElement("canvas").getContext("webgl");
        var ext = gl.getExtension("WEBGL_debug_renderer_info");
        var videocard;
        if(ext) videocard = gl.getParameter(ext.UNMASKED_RENDERER_WEBGL);

        return browser+videocard;
    }

    function UpdateLogs() {
        axios.post('https://api.0xcheats.su/Account/Coder/Server/Info', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid(), 'server': props.name }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);
        
            if (json.success == "true") {
                //Очищаем список логов
                let terminal = document.getElementById('terminal');
                while (terminal.firstChild)
                    terminal.removeChild(terminal.firstChild);

                //Добавляем текущие логи
                json.logs.forEach(function(log) {
                    const logObject = document.createElement('a');

                    if (log.includes("[Info]"))
                        logObject.style.color = "#3A96DD";
                    else if (log.includes("[Error]"))
                        logObject.style.color = "#E74856";
                    else
                        logObject.style.color = "#eee";

                    logObject.textContent = log;
                    terminal.appendChild(logObject);

                    const brObject = document.createElement('br');
                    terminal.appendChild(brObject);
                });

                //Листаем список вниз
                console.log(terminal.parentElement.scrollHeight);
                terminal.parentElement.scrollTop = terminal.parentElement.scrollHeight;

                //Доступна ли отправка новой команды
                if (json.commandPending === true) {
                    document.getElementById("command-button1").style.display = 'none';
                    document.getElementById("command-button2").style.display = 'flex';
                }
                else {
                    document.getElementById("command-button1").style.display = 'flex';
                    document.getElementById("command-button2").style.display = 'none';
                }
            }
            else if (json.failed != null) {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            lastRequestDone = true;
        });
    }

    function SendCommand() {
        //ShowError("Ещё не доделал! Железно завтра!");

        let command = document.getElementById("command-input").value;

        if (command.length < 1) {
            ShowError("Команда не может быть пустой!");
            return;
        }

        document.getElementById("command-button1").style.display = 'none';
        document.getElementById("command-button2").style.display = 'flex';

        axios.post('https://api.0xcheats.su/Account/Coder/Server/Command', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid(), 'server': props.name, 'command': command }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);
        
            if (json.success == "true") {
                ShowSuccess("Команда успешно передана на сервер!");

                document.getElementById("command-input").value = "";
            }
            else if (json.failed != null) {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {

        });
    }

    const isHover = e => e.parentElement.querySelector(':hover') === e;
    function Close() {
        document.getElementById('modal-wrapper').classList.remove('show');
        document.getElementById('modal-wrapper').classList.remove('fadeout');
        document.getElementById('modal').classList.remove('show');

        clearInterval(intervalId);
    }
    function CloseFull() {
        const hovered = isHover(document.getElementById('modal'));
        if (!hovered)
            Close();
    }
    function Show() {
        ReactDOM.render(<AccountCoder_Terminal name={props.name} ip={props.ip}/>, document.getElementById('modals'));

        setTimeout(() => {
            document.getElementById('modal-wrapper').onclick = CloseFull;
            document.getElementById('modal-wrapper').classList.add('show');
            document.getElementById('modal-wrapper').classList.add('fadeout');
            document.getElementById('modal').classList.add('show');

            document.getElementById('body-terminal').style.display = "block";

            document.getElementById('command-button1').onclick = SendCommand;
            document.getElementById('close-terminal-button').onclick = Close;

            //Очищаем список логов
            let terminal = document.getElementById('terminal');
            while (terminal.firstChild)
                terminal.removeChild(terminal.firstChild);

            UpdateLogs();

            //Логи в реальном времени
            intervalId = setInterval(() => {
                if (lastRequestDone) {
                    lastRequestDone = false;
                    UpdateLogs();
                }
            }, 3000);
        }, 200);
    }


    return (
    <>
        <div id="modal-wrapper" className="modal-wrapper" onClick={CloseFull}>
            <div id="modal" className="modal cash-up" style={{maxWidth:'50%'}}>
                <div className="header">
                    <i style={{background:'#444', marginRight:'10px'}} class="icon terminal2"></i>
                    <p className="name">Терминал {props.name}</p>
                    <span>{props.ip}</span>
                </div>

                <div id="body-terminal" className="body">
                    <div className="terminal-container">
                        <div id="terminal" class="terminal"></div>
                    </div>

                    <input id="command-input" type="text" placeholder="Введите команду..."/>

                    <div className="go-up fadeout">
                        <p id="command-button1" className="btn black clickable" style={{background: '#3DB37A', margin:'5px', 'width': '100%'}} >Выполнить команду</p>
                        <p id="command-button2" className="btn black clickable" style={{background: '#3DB37A', margin:'5px', 'width': '100%', display:'none'}} ><img src={loading_image} width='80'/></p>
                        <p id="close-terminal-button" className="btn black clickable" style={{background: '#EA384D',margin:'5px'}}>Закрыть</p>
                    </div>
                </div>

            </div>
        </div>

        <p style={{marginLeft:'10px', background:'#E4E4E400'}} className="icon-wrapper clickable" onClick={() => Show()}><i style={{background:'#444'}} class="icon terminal2"></i></p>
    </>
    );
};
export default AccountCoder_Terminal;