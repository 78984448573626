import React, { useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import qs from 'qs';

import { ShowSuccess, ShowError } from '../Modules/Popups';

import loading_image from '../../images/loading2.gif';
import visa_image from "../../images/payments/visa.png";
import sbp_image from "../../images/payments/visa.png";

const Account_Balance = (props) => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    const [OperationsActive, SetOperationsActive] = React.useState([]);
    const [OperationsInactive, SetOperationsInactive] = React.useState([]);
    const [OperationsParsed, SetOperationsParsed] = React.useState(false);

    const isHover = e => e.parentElement.querySelector(':hover') === e;
    function Close() {
        document.getElementById('modal-wrapper').classList.remove('show');
        document.getElementById('modal-wrapper').classList.remove('fadeout');
        document.getElementById('modal').classList.remove('show');
    }
    function CloseFull() {
        const hovered = isHover(document.getElementById('modal'));
        if (!hovered)
            Close();
    }
    function Show() {
        ReactDOM.render(<Account_Balance balance={props.balance} avatar={props.avatar} button={false}/>, document.getElementById('modals'));

        setTimeout(() => {
            document.getElementById('modal-wrapper').onclick = CloseFull;
            document.getElementById('modal-wrapper').classList.add('show');
            document.getElementById('modal-wrapper').classList.add('fadeout');
            document.getElementById('modal').classList.add('show');

            document.getElementById('body-operations').style.display = "block";
            document.getElementById('body-create-new').style.display = "none";

            document.getElementById('create-new-button').onclick = () => {
                document.getElementById('body-operations').style.display = "none";
                document.getElementById('body-create-new').style.display = "block";
            };
            document.getElementById('close-button').onclick = Close;
            document.getElementById('goback-button').onclick = () => {
                document.getElementById('body-operations').style.display = "block";
                document.getElementById('body-create-new').style.display = "none";
            };

            document.getElementById('create-button').onclick = CreateNew;
        }, 200);
    }

    useEffect(()=> {
        //Обновляем список операций
        RefreshOperations();
    }, [])


    function NumberFormat(value) {
        if (value > 0)
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        else
            return "";
    }

    function decrypt(encryptedData) {
        const encryptedBytes = atob(encryptedData);
        const keyBytes = unescape(encodeURIComponent("MyEncryptionKey123"));

        const decryptedBytes = new Uint8Array(encryptedBytes.length);

        for (let i = 0; i < encryptedBytes.length; i++) {
            const encryptedByte = encryptedBytes.charCodeAt(i);
            const keyByte = keyBytes.charCodeAt(i % keyBytes.length);

            // Пропускаем символы, не соответствующие ASCII
            if (encryptedByte > 255 || keyByte > 255) {
                decryptedBytes[i] = encryptedByte;
            } else {
                decryptedBytes[i] = encryptedByte ^ keyByte;
            }
        }

        const decoder = new TextDecoder('utf-8');
        const decryptedString = decoder.decode(decryptedBytes);

        return JSON.parse(decryptedString);
    }

    function GetHwid() {
        var browser = navigator.userAgent;
        var gl = document.createElement("canvas").getContext("webgl");
        var ext = gl.getExtension("WEBGL_debug_renderer_info");
        var videocard;
        if(ext) videocard = gl.getParameter(ext.UNMASKED_RENDERER_WEBGL);

        return browser+videocard;
    }

    function CreateNew() {
        let amount = document.getElementById('amount').value;
        let wallet = document.getElementById('wallet').value;
        let system = document.getElementById('system').value;

        if (amount.length == 0 || wallet.length == 0 || system.length == 0) {
            ShowError("Поля не могут быть пустыми!");
            return;
        }

        axios.post('https://api.0xcheats.su/Account/Withdraw/Create', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid(), 'amount': amount, 'wallet': wallet, 'system': system }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);

            if (json.success == "true") {
                ShowSuccess("Заявка успешно создана!");

                SetOperationsActive([]);
                SetOperationsInactive([]);
                SetOperationsParsed(false);

                Show();
                RefreshOperations();
            }
            else if (json.failed != null) {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {

        });
    }

    function RefreshOperations() {
        axios.post('https://api.0xcheats.su/Account/Withdraw/List', qs.stringify({ 'token': cookies.token, 'ahwid': GetHwid() }))
        .then(function(response) {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);

            if (json.success == "true") {
                SetOperationsActive(json.operations_active);
                SetOperationsInactive(json.operations_inactive);
                SetOperationsParsed(true);

                if (json.operations_active.length == 0)
                    document.getElementById('active-operations').style.display = "none";

                if (json.operations_inactive.length == 0)
                    document.getElementById('inactive-operations').style.display = "none";
            }
            else if (json.failed != null) {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {

        });
    }


    function ActiveOperations() {
        if (OperationsActive.length > 0) {
             return OperationsActive.map((operation) =>
                <div key={operation.amount} className="radio-boxes" style={{height:'50px'}}>
                    <div className="radio-box" style={{marginTop:"-10px"}}>
                        { operation.system === "VISA RUB" ? <img src={visa_image} style={{width:"30px", marginLeft:"-15px"}} alt="" draggable="false"/> : "" }
                        { operation.system === "SBP" ? <img src={sbp_image} style={{width:"30px", marginLeft:"-15px"}} alt="" draggable="false"/> : "" }
                        
                        <p style={{paddingLeft:"5px"}}>{operation.wallet}</p>
                        <div className="radio" style={{background:"rgba(0, 0, 0, 0)", border:"0px", width:"auto"}}>{operation.amount} {t('account_balance_operation_currency')}</div>
                    </div>
                </div>
             );
        }
        else if (!OperationsParsed) {
            return (
            <div className="radio-boxes fadeout" style={{height:'50px'}}>
                <div className="radio-box loading">
                    <div className="radio" style={{background:"rgba(0, 0, 0, 0)", border:"0px", width:"auto"}}></div>
                </div>
            </div>
            );
        }
    }
    function InactiveOperations() {
        if (OperationsInactive.length > 0) {
             return OperationsInactive.map((operation) =>
                <div key={operation.amount} className="radio-boxes" style={{height:'50px'}}>
                    <div className="radio-box" style={{marginTop:"-10px"}}>
                        { operation.system === "VISA RUB" ? <img src={visa_image} style={{width:"30px", marginLeft:"-15px"}} alt="" draggable="false"/> : "" }
                        { operation.system === "SBP" ? <img src={sbp_image} style={{width:"30px", marginLeft:"-15px"}} alt="" draggable="false"/> : "" }
                        
                        <p style={{paddingLeft:"5px"}}>{operation.wallet}</p>
                        <div className="radio" style={{background:"rgba(0, 0, 0, 0)", border:"0px", width:"auto"}}>{operation.amount} {t('account_balance_operation_currency')}</div>
                    </div>
                </div>
             );
        }
        else if (!OperationsParsed) {
            return (
            <div className="radio-boxes fadeout">
                <div className="radio-box loading" style={{'height': '50px'}}>
                    <div className="radio" style={{"background": "rgba(0, 0, 0, 0)", "border": "0px", "width": "auto"}}></div>
                </div>
                <div className="radio-box loading" style={{'height': '50px'}}>
                    <div className="radio" style={{"background": "rgba(0, 0, 0, 0)", "border": "0px", "width": "auto"}}></div>
                </div>
                <div className="radio-box loading" style={{'height': '50px'}}>
                    <div className="radio" style={{"background": "rgba(0, 0, 0, 0)", "border": "0px", "width": "auto"}}></div>
                </div>
            </div>
            );
        }
    }

    function BalanceButton() {
        if (props.button === true)
        {
            return (
                <a className="btn black go-up-balance clickable" onClick={() => Show()}>{t('account_balance_button')}</a>
            );
        }
    }

    return (
    <>
        <div id="modal-wrapper" className="modal-wrapper" onClick={CloseFull}>
            <div id="modal" className="modal cash-up">
                <div className="header">
                    <img className="avatar" alt="" src={props.avatar} draggable="false"/>
                    <p className="name">{t('account_balance_title')}</p>
                    <span>{t('account_balance_description')} <br/> <b>{NumberFormat(props.balance)}</b> {t('account_balance_currency')}</span>
                </div>

                <div id="body-operations" className="body">
                    <p id="active-operations" className="pre-title fadeout">{t('account_balance_active')}</p>
                    <ActiveOperations/>

                    <p id="inactive-operations" className="pre-title fadeout">{t('account_balance_inactive')}</p>
                    <InactiveOperations/>

                    <div className="go-up fadeout">
                        <p id="create-new-button" className="btn black clickable" style={{background: '#3DB37A', margin:'5px', 'width': '100%'}}>{t('account_balance_new')}</p>
                        <p id="close-button" className="btn black clickable" style={{background: '#EA384D',margin:'5px'}}>{t('account_balance_cancel')}</p>
                    </div>
                </div>

                <div id="body-create-new" className="body" style={{display:'none'}}>
                    <p className="pre-title fadeout">{t('account_balance_new_2')}</p>

                    <div className="go-up fadeout">
                        <input id="amount" type="text" maxLength='10' style={{width:'30%'}} placeholder={t('account_balance_new_amount')}/>
                        <input id="wallet" type="text" maxLength='20' style={{width:'50%'}} placeholder={t('account_balance_new_req')}/>
                        <select id="system" className="custom-input" style={{width:'30%'}} type="text" placeholder="">
                            <option value="VISA RUB">VISA RUB</option>
                            <option value="SBP">СБП</option>
                        </select>
                    </div>
                    <br/>
                    <div className="go-up fadeout">
                        <p id="create-button" className="btn black clickable" style={{background:'#3DB37A', margin:'5px', width:'50%'}}>{t('account_balance_new_create')}</p>
                        <p id="create-button2" className="btn black clickable" style={{background:'#3DB37A', margin:'5px', display:"none"}}><img src={loading_image} width='80'/></p>
                        <p id="goback-button" className="btn black clickable" style={{background:'#EA384D', margin:'5px', width:'50%'}}>{t('account_balance_new_back')}</p>
                    </div>

                </div>

            </div>
        </div>

        <BalanceButton/>
    </>
    );
};
export default Account_Balance;