import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from './Header';

import Popups, {ShowError} from './Modules/Popups';


const Wiki = () => {

    useEffect(()=>
    {
        
    }, [])

    var IsNumber = function IsNumber(value) {
       return typeof value === 'number' && isFinite(value);
    }
    var IsBool = function IsBool(value) {
        if (String(value) == "true" || String(value) == "TRUE" || String(value) == "false" || String(value) == "FALSE")
            return true;
            
        return false;
    }
    
    
    function CodeStart() {
        document.write('<pre style="display: block; overflow-x: auto; padding: 0.5em; color: rgb(51, 51, 51); background-color: rgb(30, 30, 30);"><code class="language-cpp" style="white-space: pre;">');
    }
    function CodeEnd() {
        document.write('</code></pre>');
    }
    
    function br() {
        document.write('<br>');
    }
    
    function Include(text) {
        document.write('<span style="color: rgb(197, 134, 192);">#include </span>');
        document.write('<span style="color: rgb(206, 145, 120);">"' + text + '"</span>');
    }
    function Var(a1, a2) {
        document.write('<span style="color: rgb(53, 140, 203);">' + a1 + ' </span>');
        
        if (IsBool(a2))
        {
            document.write('<span style="color: rgb(53, 140, 203);">' + a2 + '</span>');
        }
        else if (IsNumber(a2))
        {
            document.write('<span style="color: rgb(148, 206, 137);">' + a2 + '</span>');
        }
        else
        {
            document.write('<span style="color: rgb(156, 205, 196);">' + a2 + '</span>');
        }
    }
    function Var2(a2) {
        if (IsBool(a2))
        {
            document.write('<span style="color: rgb(53, 140, 203);">' + a2 + '</span>');
        }
        else if (IsNumber(a2))
        {
            document.write('<span style="color: rgb(148, 206, 137);">' + a2 + '</span>');
        }
        else
        {
            document.write('<span style="color: rgb(156, 205, 196);">' + a2 + '</span>');
        }
    }
    function If() {
        document.write('<span style="color: rgb(197, 134, 192); font-weight: bold;">if </span>');
    }
    function CreateVoid(name, a1, a2, a3, a4, a5, a6) {
        document.write('<span style="color: rgb(53, 140, 203);">void </span>');
        document.write('<span style="color: rgb(220, 220, 139);">' + name + '</span>');
        
        //without args
        if (a1 == null && a2 == null && a3 == null && a4 == null && a5 == null && a6 == null)
            document.write('<span style="color: rgb(255, 181, 23);">()</span>');
    }
    function Void(name, a1, a2, a3, a4, a5, a6) {
        document.write('<span style="color: rgb(220, 220, 139)">' + name + '</span>');
        
        //without args
        if (a1 == null && a2 == null && a3 == null && a4 == null && a5 == null && a6 == null)
            document.write('<span style="color: rgb(255, 181, 23)">()</span>');
        else
        {
            document.write('<span style="color: rgb(255, 181, 23)">(</span>');
            
            Var2(a1);
            if (a2 != null)
            {
                document.write('<span style="color: rgb(245, 245, 245)">, </span>');
                Var2(a2);
            }
            if (a3 != null)
            {
                document.write('<span style="color: rgb(245, 245, 245)">, </span>');
                Var2(a3);
            }
            if (a4 != null)
            {
                document.write('<span style="color: rgb(245, 245, 245)">, </span>');
                Var2(a4);
            }
            if (a5 != null)
            {
                document.write('<span style="color: rgb(245, 245, 245)">, </span>');
                Var2(a5);
            }
            if (a6 != null)
            {
                document.write('<span style="color: rgb(245, 245, 245)">, </span>');
                Var2(a6);
            }
            
            document.write('<span style="color: rgb(255, 181, 23)">)</span>');
        }
    }
    function Void2(name, a1, a2, a3, a4, a5, a6) {
        document.write('<span style="color: rgb(220, 220, 139)">' + name + '</span>');
        
        //without args
        if (a1 == null && a2 == null && a3 == null && a4 == null && a5 == null && a6 == null)
        {
            document.write('<span style="color: rgb(255, 181, 23)">()</span>');
        }
        else
        {
            document.write('<span style="color: rgb(255, 181, 23)">(</span>');
            
            Var2(a1);
            if (a2 != null)
            {
                document.write('<span style="color: rgb(245, 245, 245)">, </span>');
                Var2(a2);
            }
            if (a3 != null)
            {
                document.write('<span style="color: rgb(245, 245, 245)">, </span>');
                Var2(a3);
            }
            if (a4 != null)
            {
                document.write('<span style="color: rgb(245, 245, 245)">, </span>');
                Var2(a4);
            }
            if (a5 != null)
            {
                document.write('<span style="color: rgb(245, 245, 245)">, </span>');
                Var2(a5);
            }
            if (a6 != null)
            {
                document.write('<span style="color: rgb(245, 245, 245)">, </span>');
                Var2(a6);
            }
            
            document.write('<span style="color: rgb(255, 181, 23)">)</span>');
        }
        document.write('<span style="color: rgb(245, 245, 245)">;</span>');
    }
    
    function S() {
        br();
        document.write('<span style="color: rgb(255, 181, 23);">{</span>');
        br();
    }
    function E() {
        br();
        document.write('<span style="color: rgb(255, 181, 23);">}</span>');
        br();
    }
    function T() {
        document.write('    ');
    }
    function R() {
        document.write('<span style="color: rgb(245, 245, 245);"> = </span>');
    }
    
    //Structs
    function StructStart(name) {
        document.write('<span style="color: rgb(53, 140, 203);">struct </span>');
        document.write('<span style="color: rgb(148, 206, 137);">' + name + '</span>');
        br();
        document.write('<span style="color: rgb(225, 225, 225)">{</span>');
        br();
    }
    function StructEnd() {
        document.write('<span style="color: rgb(225, 225, 225)">}</span>');
    }
    
    function Struct_int(name) {
        T();
        document.write('<span style="color: rgb(53, 140, 203);">int </span>');
        document.write('<span style="color: rgb(225, 225, 225);">' + name + ';</span>');
        br();
    }
    function Struct_string(name) {
        T();
        document.write('<span style="color: rgb(148, 206, 137);">string </span>');
        document.write('<span style="color: rgb(225, 225, 225);">' + name + ';</span>');
        br();
    }
    function Struct_float(name) {
        T();
        document.write('<span style="color: rgb(53, 140, 203);">float </span>');
        document.write('<span style="color: rgb(225, 225, 225);">' + name + ';</span>');
        br();
    }
    function Struct_bool(name) {
        T();
        document.write('<span style="color: rgb(53, 140, 203);">bool </span>');
        document.write('<span style="color: rgb(225, 225, 225);">' + name + ';</span>');
        br();
    }
    function Struct_vector3(name) {
        T();
        document.write('<span style="color: rgb(148, 206, 137);">Vector3 </span>');
        document.write('<span style="color: rgb(225, 225, 225);">' + name + ';</span>');
        br();
    }
    function Struct_uintptr(name) {
        T();
        document.write('<span style="color: rgb(148, 206, 137);">uintptr_t </span>');
        document.write('<span style="color: rgb(225, 225, 225);">' + name + ';</span>');
        br();
    }


    function ToggleTab(tab_name) {
        let tab = document.getElementById(tab_name);
        if (tab.classList.contains('hide'))
        {
            tab.classList.remove('hide');
            tab.classList.add('show');
        }
        else
        {
            tab.classList.remove('show');
            tab.classList.add('hide');
        }
    }
    
    function setLocation(curLoc){
        
        curLoc = "?page=" + curLoc;
        
        //try {
        //  history.pushState(null, null, curLoc);
        //  return;
        //} catch(e) {}
        //location.hash = '#' + curLoc;
    }
    
    let last_tab = "Wiki_Updates";
    let last_page = "Wiki_Updates";
    function ShowPage(name)
    {
        //Tabs behavior
        if (last_tab) {
            let _tab = document.getElementById("Tab_" + last_tab);
            if (_tab.classList.contains('active'))
                _tab.classList.remove('active');
        }
        let tab = document.getElementById("Tab_" + name);
        if (tab) {
            if (!tab.classList.contains('active')) {
                tab.classList.add('active');
                last_tab = name;
            }
        }
        
        //Pages behavior
        if (last_page) {
            let _page = document.getElementById(last_page);
            if (!_page.classList.contains('hidden')) {
                _page.classList.remove('show');
                _page.classList.add('hidden');
            }
        }
        let page = document.getElementById(name);
        if (page.classList.contains('hidden')) {
            page.classList.remove('hidden');
            page.classList.add('show');
            setLocation(name);
            last_page = name;
        }
    }

    return (
    <>
        <Popups/>

        <div>
            <header>
                <div class="branding">
                    <a class="active" href="">Documentation</a>
                     / 
                    <a href="natives">API Reference</a>
                </div>
                <a aria-label="Show menu" class="showMenu " href="#"></a>
            </header>

            <div className="app lang-cs apiset-all type-docs">
                <aside>
                    <div className="docNav">
                        <ul className="section">
                            <li className="sectionTitle"><a className="active" href="/">Main page</a></li>
                            <li className="sectionTitle"><a href="natives">Natives</a></li>
                            <a id="Tab_Wiki_Updates" className="docLink active" onClick="ShowPage('Wiki_Updates')">Wiki updates</a>
                            
                            {/*Guides*/}
                            <ul className="section">
                                <li className="sectionTitle"><a className="active" onClick="ToggleTab('Guides')">Guides</a></li>
                                <ul id="Guides" className="hide">
                                    <li>
                                        <a id="Tab_Guides_Introduction" className="docLink" onClick="ShowPage('Guides_Introduction')">1. Introduction into scripting</a>
                                        <a id="Tab_Guides_First_Script" className="docLink" onClick="ShowPage('Guides_First_Script')">2. Creating your first script</a>
                                        {/*<a id="Tab_Guides_Teleport" className="docLink" onClick="ShowPage('Guides_Teleport')">3. Teleporting</a>*/}
                                    </li>
                                </ul>
                            </ul>
                            
                            {/*Classes*/}
                            <ul className="section">
                                <li className="sectionTitle"><a className="active" onClick="ToggleTab('Classes')">Classes</a></li>
                                <ul id="Classes" className="hide">
                                    <li>
                                        <a id="Tab_Classes_Ped" className="docLink" onClick="ShowPage('Classes_Ped')">Ped</a>
                                        <a id="Tab_Classes_Vehicle" className="docLink" onClick="ShowPage('Classes_Vehicle')">Vehicle</a>
                                        <a id="Tab_Classes_Object" className="docLink" onClick="ShowPage('Classes_Object')">Object</a>
                                    </li>
                                </ul>
                            </ul>
                            
                        </ul>
                    </div>
                </aside>
                
                <aside className="docToc tocEmpty"></aside>
            </div>
        </div>
    </>
    );
};
export default Wiki;