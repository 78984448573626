import React, { useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import qs from 'qs';

import {ShowError} from '../Modules/Popups';

const FooterRefund = (props) => {
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    const isHover = e => e.parentElement.querySelector(':hover') === e;
    function Close() {
        document.getElementById('modal-wrapper').classList.remove('show');
        document.getElementById('modal-wrapper').classList.remove('fadeout');
        document.getElementById('modal').classList.remove('show');

        //document.getElementById('modals').removeChild(document.getElementById('modal-wrapper'));
    }
    function CloseFull() {
        const hovered = isHover(document.getElementById('modal'));
        if (!hovered)
            Close();
    }
    function Show() {
        ReactDOM.render(<FooterRefund identifier={props.identifier} name={props.name}/>, document.getElementById('modals'));

        setTimeout(() => {
            document.getElementById('modal-wrapper').onclick = CloseFull;
            document.getElementById('modal-wrapper').classList.add('show');
            document.getElementById('modal-wrapper').classList.add('fadeout');
            document.getElementById('modal').classList.add('show');
            document.getElementById('body-loading').style.display = "block";
            //document.getElementById('body').style.display = "none";
            document.getElementById('close-refund-button').onclick = Close;
        }, 200);
    }

    return (
    <>
        <div id="modal-wrapper" className="modal-wrapper" onClick={CloseFull}>
            <div id="modal" className="modal cash-up">

                <div id="body-loading" className="body" style={{ maxHeight: "80vh", 'overflow': 'auto' }}>

                    <div style={{ display: "flex", alignItems: "center" }}>
                        <p className="pre-title2 fadeout">ПОЛИТИКА ВОЗВРАТА</p>
                        <a style={{ marginLeft: "auto" }}><div id="close-refund-button" className="pre-title2" style={{fontSize: '30px', 'cursor': 'pointer'}} onClick={Close}>x</div></a>
                    </div>


                    <div className="hl"></div>
                    <ul className="pre-text fadeout">
                        <br/><li>
                        Возврат может быть произведен только в том случае, если подписка ни разу<br/>
                        не использовалась по техническим причинам в течение первых 3 дней<br/>
                        с момента покупки.<br/>
                        </li>
                        
                        <br/><li>
                        Подписку можно обменять только на подписку равной или меньшей стоимости,<br/>
                        только в случае если вы допустили ошибку во время покупки и еще не<br/>
                        воспользовались продуктом.<br/>
                        </li>
                        
                        <br/><li>
                        Подписки не могут быть повышены/понижены до других подписок любой стоимости.<br/>
                        </li>

                        <br/><br/>
                        Мы стремимся вести свой бизнес в соответствии с этими принципами, чтобы<br/>
                        гарантировать стабильное сотрудничество и работу проекта в целом.<br/>

                        <br/><br/>
                        Последнее изменение 4 Июня 2021.
                    </ul>
                </div>

            </div>
        </div>

        <a className="clickable" onClick={() => Show()}>{t('footer_refund')}</a>
    </>
    );
};
export default FooterRefund;