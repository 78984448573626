import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import qs from 'qs';

import Account_Balance from '../Modals/Account_Balance';

import Popups, {ShowError, ShowSuccess} from '../Modules/Popups';

import {decode as base64_decode, encode as base64_encode} from 'base-64';

import empty_image from "../../images/empty.png";

const Account_Header = (props) => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    let id = props.id;
    let username = props.username;
    let avatar = props.avatar;
    let balance = props.balance;

    //Подгружаем данные из куки
    if (id == 0 || id == null) {
        if (cookies.id != null) id = base64_decode(cookies.id);
        if (cookies.username != null) username = base64_decode(cookies.username);
        if (cookies.avatar != null) avatar = base64_decode(cookies.avatar);
        balance = "";
    }
    //Сохраняем новые данные в куки
    else {
        setCookie("id", base64_encode(id), { path: "/" });
        setCookie("username", base64_encode(username), { path: "/" });
        setCookie("avatar", base64_encode(avatar), { path: "/" });
    }


    function NumberFormat(value) {
        if (value > 0)
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        else
            return "";
    }

    function ActivateCode() {
        let code = document.getElementById('activate_code').value;

        //Проверяем поле
        if (code.length < 25) {
            ShowError(t("account_header_activate_error_1"));
            return;
        }

        document.getElementById('activate_code_button').style.display = "none";
        document.getElementById('activate_code').disabled = true;

        axios.post('https://api.0xcheats.su/Account/ActivateCode', qs.stringify({ 'token': cookies.token, 'code': code }))
        .then(function(response) {
            const data = JSON.stringify(response.data);
            const json = JSON.parse(data);

            if (json.success == "true") {
                
                //Сбрасываем поле ввода
                document.getElementById('activate_code').value = "";
                
                ShowSuccess(t("account_header_activate_success_1"));
            }
            else if (json.failed != null) {
                ShowError(json.failed);
            }
        })
        .catch(function(error) {
            ShowError(error);
        })
        .finally(function() {
            document.getElementById('activate_code_button').style.display = "flex";
            document.getElementById('activate_code').disabled = false;
        });
    }

    function MobileMenu() {
        let mobileMenu = document.getElementById("mobile-menu");
        let mobileMenuOverlay = document.getElementById("mobile-menu-overlay");
        if (mobileMenu != null && mobileMenuOverlay != null) {
            //Если меню открыто
            if (mobileMenu.classList.contains("active")) {
                mobileMenu.classList.remove("active");
                mobileMenuOverlay.classList.remove("show");
            }
            //Если меню закрыто
            else {
                mobileMenu.classList.add("active");
                mobileMenuOverlay.classList.add("show");
            }
        }
    }


    if (id == null || id == 0)
        return (
            <div className="header">
                <div className="profile-user">
                    <img className="loading" src={empty_image} alt="" draggable="false"/>
                    <div>
                        <p className="name loading">Username #12345</p>
                        <br/>
                    </div>
                </div>
                <div className="input activate-key"></div>
                <div className="payment-block"></div>
                <label className="mobile-menu">
                    <input type="checkbox" id="check"/>
                    <span></span><span></span><span></span>
                </label>
            </div>
        );
    else
        return (
        <>
            <div id="mobile-menu-overlay" className="mobile-menu-overlay">
                <Link to="/" className="link">{t('account_header_general')}</Link>
                <Link to="/account" className="link">{t('account_header_subscriptions')}</Link>
                {/*<Link to="/account/coder" className="link">Кодер</Link>*/}
                {/*<Link to="/account/reseller" className="link">Реселлер</Link>*/}
                <Link to="/account/settings" className="link">{t('account_header_settings')}</Link>
                <Link to="/account/help" className="link">{t('account_header_support')}</Link>
            </div>

            <div className="header">
                <div className="profile-user">
                    <img src={avatar} alt="" draggable="false"/>
                    {/*<img src="https://0xcheats.net/res/images/avatars/bg/fire.png" style={{}} alt="" draggable="false"/>*/}
                    <div>
                        <p className="name">{username} #{id}</p>
                        <span>{t('account_header_username_id')}</span>
                    </div>
                </div>
                <div className="input activate-key">
                    <p id="activate_code_button" className="btn black" onClick={() => ActivateCode()}>{t('account_header_activate')}</p>
                    <input id="activate_code" type="text" placeholder={t('account_header_activate_2')}/>
                </div>
                <div className="payment-block">
                    <div className="balance">
                        <Account_Balance token={cookies.token} balance={balance} avatar={avatar} button={true}/>
                        <span>{NumberFormat(balance)} <b>{t('account_header_currency')}</b></span>
                    </div>
                </div>
                <label id="mobile-menu" className="mobile-menu"><input type="checkbox" id="check" onClick={MobileMenu}/><span></span><span></span><span></span></label>
            </div>
        </>
        );
};
export default Account_Header;