import React, { useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import qs from 'qs';

import {ShowError} from '../Modules/Popups';

const FooterTerms = (props) => {
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    const isHover = e => e.parentElement.querySelector(':hover') === e;
    function Close() {
        document.getElementById('modal-wrapper').classList.remove('show');
        document.getElementById('modal-wrapper').classList.remove('fadeout');
        document.getElementById('modal').classList.remove('show');
    }
    function CloseFull() {
        const hovered = isHover(document.getElementById('modal'));
        if (!hovered)
            Close();
    }
    function Show() {
        ReactDOM.render(<FooterTerms identifier={props.identifier} name={props.name}/>, document.getElementById('modals'));

        setTimeout(() => {
            document.getElementById('modal-wrapper').onclick = CloseFull;
            document.getElementById('modal-wrapper').classList.add('show');
            document.getElementById('modal-wrapper').classList.add('fadeout');
            document.getElementById('modal').classList.add('show');
            document.getElementById('body-loading').style.display = "block";
            //document.getElementById('body').style.display = "none";
            document.getElementById('close-terms-button').onclick = Close;
        }, 200);
    }

    return (
    <>
        <div id="modal-wrapper" className="modal-wrapper" onClick={CloseFull}>
            <div id="modal" className="modal cash-up">

                <div id="body-loading" className="body" style={{ maxHeight: "80vh", 'overflow': 'auto' }}>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p className="pre-title2 fadeout">УСЛОВИЯ ИСПОЛЬЗОВАНИЯ</p>
                        <a style={{ marginLeft: "auto" }}><div id="close-terms-button" className="pre-title2" style={{ fontSize: '30px', cursor: 'pointer'}} onClick={Close}>x</div></a>
                    </div>

                    <div className="h1"></div>
                    <ul className="pre-text fadeout">
                        
                        <b>1. Acceptance of Terms of Use</b><br/><br/>
                        By accessing this web site, you are agreeing to be bound by these Terms<br/>
                        and Conditions of Use, all applicable laws and regulations, and agree that<br/>
                        you are responsible for compliance with any applicable local laws. If you do<br/>
                        not agree with any of these terms, you are prohibited from using or accessing<br/>
                        this site. The materials contained in this web site are protected by applicable<br/>
                        copyright and trade mark law.<br/>
                        
                        <br/>
                        <b>2. Use License</b><br/><br/>
                        <b>2.1.</b> Permission is granted to temporarily download one copy of the materials<br/>
                        (information or software) on 0xCheats web site for personal, non-commercial<br/>
                        transitory viewing only. This is the grant of a license, not a transfer of title,<br/>
                        and under this license you may not:<br/>
                        
                        <br/><li>
                        Modify or copy the materials;<br/>
                        </li>

                        <br/><li>
                        Use the materials for any commercial purpose, or for any public display (commercial or<br/>
                        non-commercial) without our permission;<br/>
                        </li>

                        <br/><li>
                        Attempt to decompile or reverse engineer any software contained on 0xCheats web site;<br/>
                        </li>

                        <br/><li>
                        Remove any copyright or other proprietary notations from the materials;<br/>
                        </li>

                        <br/><li>
                        Transfer the materials to another person or "mirror" the materials on any other server;<br/>
                        </li>

                        <br/>
                        <b>2.2.</b> This license shall automatically terminate if you violate any of these restrictions and<br/>
                        may be terminated by 0xCheats at any time. Upon terminating your viewing of these materials<br/>
                        or upon the termination of this license, you must destroy any downloaded materials in your<br/>
                        possession whether in electronic or printed format.<br/>

                        <br/>
                        <b>3. Disclaimer</b><br/><br/>
                        The materials on 0xCheats's web site are provided "as is". 0xCheats makes no warranties, expressed<br/>
                        or implied, and hereby disclaims and negates all other warranties, including without limitation,<br/>
                        implied warranties or conditions of merchantability, fitness for a particular purpose, or<br/>
                        non-infringement of intellectual property or other violation of rights. Further, 0xCheats does not<br/>
                        warrant or make any representations concerning the accuracy, likely results, or reliability of the<br/>
                        use of the materials on its Internet web site or otherwise relating to such materials or on any<br/>
                        sites linked to this site.<br/>

                        <br/>
                        <b>4. Limitations</b><br/><br/>
                        In no event shall 0xCheats or its suppliers be liable for any damages (including, without limitation,<br/>
                        damages for loss of data or profit, or due to business interruption,) arising out of the use or<br/>
                        inability to use the materials on 0xCheats Internet site, even if 0xCheats or a 0xCheats authorized<br/>
                        representative has been notified orally or in writing of the possibility of such damage. Because<br/>
                        some jurisdictions do not allow limitations on implied warranties, or limitations of liability for<br/>
                        consequential or incidental damages, these limitations may not apply to you.<br/>

                        <br/>
                        <b>5. Revisions and Errata</b><br/><br/>
                        The materials appearing on 0xCheats's web site could include technical, typographical, or photographic<br/>
                        errors. 0xCheats does not warrant that any of the materials on its web site are accurate, complete, or<br/>
                        current. 0xCheats may make changes to the materials contained on its web site at any time without notice.<br/>
                        0xCheats does not, however, make any commitment to update the materials.<br/>

                        <br/>
                        <b>6. Links</b><br/><br/>
                        0xCheats has not reviewed all of the sites linked to its Internet web site and is not responsible for<br/>
                        the contents of any such linked site. The inclusion of any link does not imply endorsement by 0xCheats<br/>
                        of the site. Use of any such linked web site is at the user's own risk.<br/>

                        <br/>
                        <b>7. Site Terms of Use Modifications</b><br/><br/>
                        0xCheats may revise these terms of use for its web site at any time without notice. By using this web<br/>
                        site you are agreeing to be bound by the then current version of these Terms and Conditions of Use.<br/>

                        <br/>
                        <b>8. Governing Law</b><br/><br/>
                        Any claim relating to 0xCheats web site shall be governed by the laws of the Switzerland without<br/>
                        regard to its conflict of law provisions.<br/>

                        <br/>
                        <b>9. Subscription</b><br/><br/>
                        The subscription is valid for the time purchased. When expired, the user is required to renew it manually.<br/>
                        Users caught abusing third party payment services to obtain free services will be immediately and<br/>
                        permanently banned.<br/>
                        Users caught selling subscriptions, cracking or alterating the behavior of the software will be<br/>
                        immediately and permanently banned.<br/>
                        Subscriptions may be terminated by 0xCheats at any time without warning and without liability, whether<br/>
                        financial or not.<br/>

                        <br/>
                        <b>10. Online Gaming</b><br/><br/>
                        0xCheats accepts no responsibility to your online gaming accounts as a result of using this website or any<br/>
                        software, or data contained within. You, the user, are solely responsible for your online gaming activities<br/>
                        and any loss to any of your online gaming accounts, whether financial or not, is your responsibility.<br/>

                        <br/>
                        <b>11. Payment Policy</b><br/><br/>
                        Fraudulent charges with an account that sends money without the consent of the account holder or/and any kind<br/>
                        of frauds will result in your account being instantly disabled, permanently terminated and/or legal action will<br/>
                        be taken. We do not allow the user to share or resell his account, it will result in your account being instantly<br/>
                        disabled, permanently terminated and/or legal action will be taken.<br/>


                        <br/><br/>
                        Последнее изменение 31 Мая 2021.
                    </ul>
                </div>

            </div>
        </div>

        <a className="clickable" onClick={() => Show()}>{t('footer_terms')}</a>
    </>
    );
};
export default FooterTerms;