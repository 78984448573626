import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './css/styles.css';
import './css/styles_custom.css';
import './css/webfonts/stylesheet.css';
import './css/owl.carousel.min.css';
import './css/icons.css';

import Index from './pages/Index';
import Games from './pages/Games';
import Game from './pages/Game';
import Feed from './pages/Feed';
import Wiki from './pages/Wiki';
import Scripts from './pages/Scripts';

import Auth from './pages/Auth';
import Register from './pages/Register';
import AuthForgot from './pages/AuthForgot';
import Recovery from './pages/Recovery';
import Account from './pages/Account';
import AccountDownload from './pages/AccountDownload';
import AccountSettings from './pages/AccountSettings';
import AccountCoder from './pages/AccountCoder';
import AccountReseller from './pages/AccountReseller';
import AccountHelp from './pages/AccountHelp';
import AccountSupporter from './pages/AccountSupporter';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          
          <Route path="*" element={<Index/>}/>
          <Route path="/" element={<Index/>}/>
          <Route path="/games" element={<Games/>}/>
          <Route path="/games/:identifier" element={<Game/>}/>
          <Route path="/games/:identifier/wiki" element={<Wiki/>}/>
          <Route path="/games/:identifier/scripts" element={<Scripts/>}/>
          <Route path="/feed" element={<Feed/>}/>
          
          <Route path="/auth" element={<Auth/>}/>
          <Route path="/register" element={<Register/>}/>
          <Route path="/auth/forgot" element={<AuthForgot/>}/>
          <Route path="/recovery/:code" element={<Recovery/>}/>
          <Route path="/account" element={<Account/>}/>
          <Route path="/account/download/:token/:identifier" element={<AccountDownload/>}/>
          <Route path="/account/settings" element={<AccountSettings/>}/>
          <Route path="/account/coder" element={<AccountCoder/>}/>
          <Route path="/account/reseller" element={<AccountReseller/>}/>
          <Route path="/account/help" element={<AccountHelp/>}/>
          <Route path="/account/supporter" element={<AccountSupporter/>}/>

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;