import React, { useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useCookies } from 'react-cookie';

import axios from 'axios';
import qs from 'qs';

import {ShowSuccess, ShowError} from '../Modules/Popups';


const AccountSupporter_Events = (props) => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);

    function decrypt(encryptedData) {
        const encryptedBytes = atob(encryptedData);
        const keyBytes = unescape(encodeURIComponent("MyEncryptionKey123"));

        const decryptedBytes = new Uint8Array(encryptedBytes.length);

        for (let i = 0; i < encryptedBytes.length; i++) {
            const encryptedByte = encryptedBytes.charCodeAt(i);
            const keyByte = keyBytes.charCodeAt(i % keyBytes.length);

            // Пропускаем символы, не соответствующие ASCII
            if (encryptedByte > 255 || keyByte > 255) {
                decryptedBytes[i] = encryptedByte;
            } else {
                decryptedBytes[i] = encryptedByte ^ keyByte;
            }
        }

        const decoder = new TextDecoder('utf-8');
        const decryptedString = decoder.decode(decryptedBytes);

        return JSON.parse(decryptedString);
    }
    function GetHwid() {
        var browser = navigator.userAgent;
        var gl = document.createElement("canvas").getContext("webgl");
        var ext = gl.getExtension("WEBGL_debug_renderer_info");
        var videocard;
        if(ext) videocard = gl.getParameter(ext.UNMASKED_RENDERER_WEBGL);

        return browser+videocard;
    }

    function DateFormat(date) {
        const newDate = new Date(date);
        const day = newDate.getDate().toString().padStart(2, '0');
        const month = (newDate.getMonth() + 1).toString().padStart(2, '0'); // Месяцы начинаются с 0
        const year = newDate.getFullYear();
        const hours = newDate.getHours().toString().padStart(2, '0');
        const minutes = newDate.getMinutes().toString().padStart(2, '0');
        const formattedDate = `${day}.${month}.${year} в ${hours}:${minutes}`;
        return formattedDate;
    }

    function SendEvent(newEvent) {
        axios.post('https://api.0xcheats.su/Account/Supporter/Ticket/Events', qs.stringify({ 'token': props.token, 'ahwid': GetHwid(), 'ticket': props.ticket, 'event': newEvent }))
        .then(function(response)
        {
            const data = JSON.stringify(decrypt(response.data));
            const json = JSON.parse(data);
            
            if (json.success == "true")
            {
                ShowSuccess("Действие выполнено!");
                window.location.reload();
            }
            else if (json.failed !== null)
            {
                ShowError(json.failed);
            }
        })
        .catch(function(error)
        {
            ShowError(error);
        })
        .finally(function()
        {
            
        });
    };

    const isHover = e => e.parentElement.querySelector(':hover') === e;
    function Close() {
        document.getElementById('modal-wrapper').classList.remove('show');
        document.getElementById('modal-wrapper').classList.remove('fadeout');
        document.getElementById('modal').classList.remove('show');
    }
    function CloseFull() {
        const hovered = isHover(document.getElementById('modal'));
        if (!hovered)
            Close();
    }
    function Show() {
        ReactDOM.render(<AccountSupporter_Events token={props.token} ticket={props.ticket}/>, document.getElementById('modals'));

        setTimeout(() => {
            document.getElementById('modal-wrapper').onclick = CloseFull;
            document.getElementById('modal-wrapper').classList.add('show');
            document.getElementById('modal-wrapper').classList.add('fadeout');
            document.getElementById('modal').classList.add('show');

            document.getElementById("modal_body").style.display = "none";
            document.getElementById("modal_body_partners").style.display = "none";
            document.getElementById("modal_body_subscriptions").style.display = "none";
            document.getElementById("modal_body_operations").style.display = "none";

            axios.post('https://api.0xcheats.su/Account/Supporter/Ticket/Info', qs.stringify({ 'token': props.token, 'ahwid': GetHwid(), 'ticket': props.ticket }))
            .then(function(response)
            {
                const data = JSON.stringify(decrypt(response.data));
                const json = JSON.parse(data);
                
                if (json.success == "true")
                {
                    document.getElementById("modal_body").style.display = "block";

                    const subscriptions_list = document.getElementById('modal_subscriptions'); while (subscriptions_list.firstChild) subscriptions_list.removeChild(subscriptions_list.firstChild);
                    json.subscriptions.forEach(function(subscription) {
                        const subscriptionObject = document.createElement('div');
                        const subscriptionInfo = document.createElement('p');
                        subscriptionInfo.textContent = subscription.game + ' | '+ subscription.hours +' ч. | Последний запуск ' + DateFormat(subscription.last_launch);
                        subscriptionObject.appendChild(subscriptionInfo);
                        document.getElementById('modal_subscriptions').appendChild(subscriptionObject);
                    });

                    const operations_list = document.getElementById('modal_operations'); while (operations_list.firstChild) operations_list.removeChild(operations_list.firstChild);
                    json.operations.forEach(function(operation) {
                        const operationObject = document.createElement('div');
                        const operationInfo = document.createElement('p');
                        operationInfo.textContent = operation.game + ' | '+ operation.hours +' ч. | Куплено ' + DateFormat(operation.date);
                        operationObject.appendChild(operationInfo);
                        document.getElementById('modal_operations').appendChild(operationObject);
                    });

                    //Кто последний ответил
                    if (json.last_answer == "user") {
                        document.getElementById("button_mark_as_read").style.display = "flex";
                    }
                    else {
                        document.getElementById("button_mark_as_read").style.display = "none";
                    }

                    //Админ тикет?
                    if (json.status == 2) {
                        document.getElementById("button_send_to_admin").style.display = "none";
                    }
                    else if (json.status == 1) {
                        document.getElementById("button_send_to_admin").style.display = "flex";
                    }
                }
                else if (json.failed !== null)
                {
                    ShowError(json.failed);
                }
            })
            .catch(function(error)
            {
                ShowError(error);
            })
            .finally(function()
            {
                
            });
        }, 200);
    }

    function ShowPartners() {
        document.getElementById("modal_body").style.display = "none";
        document.getElementById("modal_body_partners").style.display = "block";
        document.getElementById("modal_body_subscriptions").style.display = "none";
        document.getElementById("modal_body_operations").style.display = "none";
    }
    function ShowSubscriptions() {
        document.getElementById("modal_body").style.display = "none";
        document.getElementById("modal_body_partners").style.display = "none";
        document.getElementById("modal_body_subscriptions").style.display = "block";
        document.getElementById("modal_body_operations").style.display = "none";
    }
    function ShowOperations() {
        document.getElementById("modal_body").style.display = "none";
        document.getElementById("modal_body_partners").style.display = "none";
        document.getElementById("modal_body_subscriptions").style.display = "none";
        document.getElementById("modal_body_operations").style.display = "block";
    }
    function ShowMainBody() {
        document.getElementById("modal_body").style.display = "block";
        document.getElementById("modal_body_partners").style.display = "none";
        document.getElementById("modal_body_subscriptions").style.display = "none";
        document.getElementById("modal_body_operations").style.display = "none";
    }


    return (
    <>
        <div id="modal-wrapper" className="modal-wrapper" onClick={CloseFull}>
            <div id="modal" className="modal cash-up">
                <div className="header">
                    {/*<img className="avatar" alt="" src={"https://api.0xcheats.su/Images/Games/" + props.identifier +"/logo.jpg"}/>*/}
                    <p className="name">Обращение #{props.ticket}</p>
                    <span>Возможные <br/> действия</span>
                </div>

                <div id="modal_body" className="body" style={{display:'none'}}>
                    <p className="pre-title fadeout">Основные действия</p>
                    <p id="button_mark_as_read" className="btn black clickable" style={{background:'#000', margin:'5px', width:'100%'}} onClick={() => SendEvent("mark_as_read")}>Отметить прочитанным</p>
                    <p className="btn black clickable" style={{background:'#000', margin:'5px', width:'100%'}} onClick={() => SendEvent("close_ticket")}>Закрыть тикет</p>
                    <p id="button_send_to_admin" className="btn black clickable" style={{background:'#000', margin:'5px', width:'100%'}} onClick={() => SendEvent("send_to_admin")}>Отправить к админу</p>
                    
                    <br/>
                    <p className="pre-title fadeout">Другое</p>
                    <p className="btn black clickable" style={{background:'#6382F5', margin:'5px', width:'100%'}} onClick={() => ShowPartners()}>Сотрудничество</p>
                    <p className="btn black clickable" style={{background:'#6382F5', margin:'5px', width:'100%'}} onClick={() => ShowSubscriptions()}>Управление подписками</p>
                    <p className="btn black clickable" style={{background:'#6382F5', margin:'5px', width:'100%'}} onClick={() => ShowOperations()}>История покупок</p>

                    {/*<div className="go-up fadeout">
                        <p id="save-changes" className="btn black clickable" style={{background:'#3DB37A', margin:'5px', width:'100%'}}>Сохранить изменения</p>
                        <p id="close-button" className="btn black clickable" style={{background:'#000', margin:'5px', width:'100%'}}>Назад</p>
                    </div>*/}
                </div>

                <div id="modal_body_partners" className="body" style={{display:'none'}}>
                    <p className="pre-title fadeout">Сотрудничество</p>
                    <div className="go-up fadeout">
                        <p id="close-button" className="btn black clickable" style={{background:'#000', margin:'5px', width:'100%'}} onClick={() => ShowMainBody()}>Назад</p>
                    </div>
                </div>

                <div id="modal_body_subscriptions" className="body" style={{display:'none'}}>
                    <p className="pre-title fadeout">Подписки</p>
                    <div id="modal_subscriptions"></div>
                    <div className="go-up fadeout">
                        <p id="close-button" className="btn black clickable" style={{background:'#000', margin:'5px', width:'100%'}} onClick={() => ShowMainBody()}>Назад</p>
                    </div>
                </div>

                <div id="modal_body_operations" className="body" style={{display:'none'}}>
                    <p className="pre-title fadeout">История покупок</p>
                    <div id="modal_operations"></div>
                    <div className="go-up fadeout">
                        <p id="close-button" className="btn black clickable" style={{background:'#000', margin:'5px', width:'100%'}} onClick={() => ShowMainBody()}>Назад</p>
                    </div>
                </div>

            </div>
        </div>

        <p id="ticket_close_button" onClick={() => Show()}><i className="icon ticket_more2"></i></p>
    </>
    );
};
export default AccountSupporter_Events;